import { GetStaticProps } from "next";
import { z } from "zod";
import { Homepage, HomepageProps } from "~/components/Homepage";
import { apiToHomepageHeaderSectionProps } from "~/components/HomepageHeaderSection";
import { apiToHomepageListCtaSectionProps } from "~/components/HomepageListCtaSection";
import { apiToHomepageTabbedGridSectionProps } from "~/components/HomepageTabbedGridSection";
import {
  fetchHomepage,
  fetchHomepagePreview,
} from "~/lib/server/asgardApiCalls";
import { wrapGetStaticProps } from "~/lib/server/wrapGetProps";
import { getValueOrDefault } from "~/lib/shared/getValueOrDefault";
import { heDecodeOrDefault } from "~/lib/shared/html-utils";
import { OptionData } from "~/ui-library/components/Select";

const CLEAR_ITEM: OptionData = { id: "", label: "All" };

export const getStaticProps: GetStaticProps<HomepageProps> = wrapGetStaticProps(
  async ({ preview }) => {
    const fetchMethod = preview ? fetchHomepagePreview : fetchHomepage;
    const { data } = await fetchMethod();

    return {
      props: {
        headerSectionProps: apiToHomepageHeaderSectionProps(data.hero),
        listCtaSectionProps: apiToHomepageListCtaSectionProps(data.subscribe),
        tabbedGridSectionProps: apiToHomepageTabbedGridSectionProps(
          data.latest
        ),
        meta: {
          seo: data.seo,
          structuredData: data.structuredData,
          piano: {
            type: "simple",
          },
        },
        searchProps: {
          categoryOptions: [CLEAR_ITEM].concat(
            data.searchOptions.categoryOptions
              .sort(({ name: labelOne }, { name: labelTwo }) =>
                labelOne.localeCompare(labelTwo)
              )
              .map<OptionData>(({ id, name }) => ({
                id: z.coerce.string().parse(id),
                label: heDecodeOrDefault(name),
              }))
          ),
          contentTypeOptions: [CLEAR_ITEM].concat(
            data.searchOptions.contentTypeOptions
              .sort(({ label: labelOne }, { label: labelTwo }) =>
                labelOne.localeCompare(labelTwo)
              )
              .map<OptionData>(({ value, label }) => ({
                id: z.coerce.string().parse(value),
                label: heDecodeOrDefault(label),
              }))
          ),
          filterTopics: data.searchOptions.topicOptions
            .sort(({ name: labelOne }, { name: labelTwo }) =>
              labelOne.localeCompare(labelTwo)
            )
            .map(({ id, name }) => ({
              id: z.coerce.string().parse(id),
              label: heDecodeOrDefault(name),
            })),
          pageSize: z.coerce
            .number()
            .int()
            .parse(getValueOrDefault(data.searchOptions.pageSize, "12")),
          sectionTitle: getValueOrDefault(data.searchOptions.sectionTitle, ""),
        },
      } satisfies HomepageProps,
    };
  }
);

export default Homepage;
